import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import { useEffect, useState } from "react";
import { FideliteInterface } from "../../interfaces/FideliteInterface";
import ProductsAPI from "../../services/API/clients/ProductsAPI";
import handleErrorResponse from "../../hooks/HandleErrors";

export default function ModalFidelity({ open, setOpen, idToEdit }: Readonly<{ open: boolean; setOpen: (open: boolean) => void; idToEdit?: string }>) {
    const [categoryIsIncorrect, setCategoryIsIncorrect] = useState<boolean>(true);
    const [oldDatas, setOldDatas] = useState<FideliteInterface>({
        id: "",
        productName: "",
        description: "",
        magasin: "",
        point: 0,
    });

    const fetchData = async () => {
        if (!idToEdit || idToEdit === "") return;
        console.log(idToEdit)
        const response = await ProductsAPI.product(idToEdit);
        if (response.status === 200 || response.status === 201) {
            setOldDatas({
                id: response.body.id,
                productName: response.body.name,
                description: response.body.description,
                magasin: response.body.category,
                point: 0
            })
        } else {
            handleErrorResponse(response);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCategoryIsIncorrect(true);
        if (idToEdit && idToEdit !== "") {
            const datas = {
                name: oldDatas.productName,
                description: oldDatas.description,
                category: oldDatas.magasin
            }
            const response = await ProductsAPI.edit(oldDatas.id, datas);
            if (response.status === 200 || response.status === 201) {
                setOpen(false);
                clearDatas();
            } else {
                handleErrorResponse(response);
                if (response.body.title === "Bad Request")
                    setCategoryIsIncorrect(false);
            }
        } else {
            createObjet();
        }
    };

    const createObjet = async () => {
        const datas = {
            name: oldDatas.productName,
            description: oldDatas.description,
            category: oldDatas.magasin
        }
        const response = await ProductsAPI.new(datas);
        if (response.status === 200 || response.status === 201) {
            setOpen(false);
            clearDatas()
        } else {
            handleErrorResponse(response);
            if (response.body.title === "Bad Request")
                setCategoryIsIncorrect(false);
        }
    }

    const clearDatas = () => {
        setOldDatas({
            id: "",
            productName: "",
            description: "",
            magasin: "",
            point: 0
        });
    }

    useEffect(() => {
        if (idToEdit || idToEdit === "") {
            fetchData();
        }
    }, [idToEdit]);

    const deleteMyObject = async () => {
        if (idToEdit && idToEdit !== "") {
            const response = await ProductsAPI.delete(idToEdit);
            if (response.status === 200 || response.status === 201) {
                setOpen(false);
                setOldDatas({
                    id: "",
                    productName: "",
                    description: "",
                    magasin: "",
                    point: 0
                });
            } else {
                handleErrorResponse(response);
            }
        }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white px-16 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {idToEdit ? "Modification d'un" : "Nouveau"} produit
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-6">
                    <input
                        type="text"
                        placeholder="Nom"
                        className="border rounded-lg p-2"
                        value={oldDatas?.productName}
                        onChange={(e) => setOldDatas({ ...oldDatas, productName: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Description"
                        className="border rounded-lg p-2"
                        value={oldDatas?.description}
                        onChange={(e) => setOldDatas({ ...oldDatas, description: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Catégorie"
                        className={`border rounded-lg p-2 ${categoryIsIncorrect ? "" : "border-red-500"}`}
                        value={oldDatas?.magasin}
                        onChange={(e) => setOldDatas({ ...oldDatas, magasin: e.target.value })}
                    />
                    <div className="flex gap-4">
                        <button type="submit" className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans">
                            {idToEdit ? "Modifier" : "Ajouter"}
                        </button>
                        {idToEdit && (
                            <button onClick={() => deleteMyObject()} className="rounded-full border bg-red-300 text-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans">
                                Suppression
                            </button>
                        )}
                    </div>
                </form>
            </Dialog.Panel>
        </ModalWrapper>
    );
}