import APIClient from "../APIClients";

export default class ProductsAPI {
    public static async products() {
        return APIClient.interceptor.get(`/product`);
    }

    public static async edit(id: string, data: { name: string; description: string; category: string; }) {
        return APIClient.interceptor.put(`/product/${id}`, data);
    }

    public static async delete(id: string) {
        return APIClient.interceptor.delete(`/product/${id}`);
    }

    public static async product(id: string) {
        return APIClient.interceptor.get(`/product/${id}`);
    }

    public static async new(data: { name: string; description: string; category: string }) {
        return APIClient.interceptor.post(`/product`, data);
    }
}
