import { Typography } from "@material-tailwind/react";
import "tailwindcss/tailwind.css";

import NavLinks from "./NavLink";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Promotion from "../FirstPart/Promotion";
import Publicity from "../FirstPart/Publicity";
import Product from "../FirstPart/Product";
import Profil from "../SecondPart/Profil";
import Dash from "./Dash";

import LogoTimi from "../../../assets/logoTimi.png"
import BorderBottom from "../../../assets/bottomBorderWhite.png"
import Statistics from "../FirstPart/Statistics";

interface AsideProps {
    open: boolean;
    handleOpen: () => void;
}

interface RouteDisplay {
    icon?: JSX.Element;
    name: string;
}

interface RouteData {
    path: string;
    display?: RouteDisplay;
    element?: JSX.Element;
    routes?: RouteData[];
}

export interface RouteGroup {
    baseUrl?: string;
    title?: string;
    shownInSidebar?: boolean;
    element?: JSX.Element;
    pages: RouteData[];
}

const routes: RouteGroup[] = [
    {
        baseUrl: "dash",
        element: <Dash />,
        shownInSidebar: true,
        pages: [
            {
                display: {
                    icon: <FontAwesomeIcon icon="box" className="w-5 h-5 text-inherit" />,
                    name: "Produits",
                },
                path: "products",
                element: <Product />,
            },
            {
                display: {
                    icon: <FontAwesomeIcon icon="chart-simple" className="w-5 h-5 text-inherit" />,
                    name: "Statistics",
                },
                path: "statistics",
                element: <Statistics />,
            },
            {
                display: {
                    icon: <FontAwesomeIcon icon="piggy-bank" className="w-5 h-5 text-inherit" />,
                    name: "Promotions",
                },
                path: "promotion",
                element: <Promotion />,
            },
            {
                display: {
                    icon: <FontAwesomeIcon icon="bullhorn" className="w-5 h-5 text-inherit" />,
                    name: "Pulicités",
                },
                path: "publicity",
                element: <Publicity />,
            },
        ],
    },
    {
        title: "Paramètres",
        baseUrl: "dash",
        element: <Dash />,
        shownInSidebar: true,
        pages: [
            {
                display: {
                    icon: <FontAwesomeIcon icon="user" className="w-5 h-5 text-inherit" />,
                    name: "Profil",
                },
                path: "profil",
                element: <Profil />,
            },
        ],
    },
];

export default function Aside({ open, handleOpen }: Readonly<AsideProps>): React.JSX.Element {
    const asideRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (asideRef.current && !asideRef.current.contains(event.target as Node)) {
                handleOpen();
            }
        }
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, handleOpen]);

    return (
        <>
        <aside
                ref={asideRef}
                className={`bg-white border-2 rounded-t-sm border-black ${
                    open ? "translate-x-0" : "-translate-x-80"
                } fixed inset-0 z-40 my-4 ml-4 h-[calc(100vh-32px)] w-72 transition-transform duration-300 xl:translate-x-0 overflow-y-auto scrollPretty`}
            >
                <img src={LogoTimi} alt="Logo Timi" className="h-20 mx-auto mt-4" />
                <hr className="mt-4 mb-2 mx-4 border-t-purple-50" />
                <div className="mx-4 mb-4">
                    {routes.map(
                        ({ baseUrl, pages, title, shownInSidebar }) =>
                            shownInSidebar && (
                                <div key={title}>
                                    {title && (
                                        <Typography onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="small" className="text-black mx-3.5 mt-4 mb-2 font-black uppercase opacity-75">
                                            {title}
                                        </Typography>
                                    )}
                                    <ul className={`mb-4 flex flex-col gap-4 ${title === "Gestion" && "otherTutorials-step"}`}>
                                        {pages
                                            .filter((p) => p.display)
                                            .map(({ display, path }) => (
                                                <li key={display ? display.name : "randomName"}>
                                                    <NavLinks
                                                        layout={baseUrl}
                                                        path={`/${path}`}
                                                        handleOpen={handleOpen}
                                                        icon={display?.icon}
                                                        name={display?.name ?? path}
                                                    />
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            )
                    )}
                </div>
            </aside>
            <div className="w-72 fixed inset-0 top-[calc(100vh-40px)] my-4 ml-4 z-50">
                <img src={BorderBottom} alt="Border Bottom" className="w-full" />
            </div>
        </>
    );
}
